<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <v-card elevation="0">
            <v-card-actions>
                <v-card-title>{{ formTitle }}</v-card-title>
                <v-spacer></v-spacer>
                <v-btn :to="{ name: 'Users' }"
                    color="green"
                    rounded
                >
                {{$t('Cancel')}}
                </v-btn>
                <v-btn
                :disabled="!valid"
                color="primary"
                rounded
                @click="save"
                >
                {{$t('Save')}}
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-text-field
                            v-model="item.firstName"
                            :label="$t('First Name')"
                            :rules="rules.firstName"
                            required
                        ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-text-field
                            v-model="item.lastName"
                            :label="$t('Last Name')"
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-text-field
                            v-model="item.email"
                            :label="$t('Email')"
                            :rules="rules.email"
                            required
                        ></v-text-field>
                        <v-btn :loading="sendActivateLoading" text plain color="primary" class="pa-0 text-caption text-decoration-underline" v-if="id != ''" @click.stop="sendActiveEmail()" >{{$t('Send activate user email')}}</v-btn>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="roles"
                        item-text="name"
                        item-value="value"
                        v-model="item.role"
                        :label="$t('User Role')"
                        :rules="rules.role"
                        required
                        >
                            <template v-slot:item="{item}">
                                {{$t(item.name)}}
                            </template>
                        </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
export default {

    data() {
        return {
            valid: true,
            sendActivateLoading:false,
            item: {},
            roles:[],
            id:"",
            loading:false,
            rules: {
                firstName:[
                    value => !!value || this.$t('First Name is Required'),
                    value => (value || '').length <= 100 || this.$t('Max 100 characters'),
                ],
                email:[
                    value => !!value || this.$t('Email is Required.'),
                    v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
                ],
                role:[
                    value => !!value || value == 0 || this.$t('User Role is Required.'),
                ],
            },
        }
    },

    computed: {
        formTitle () {
            return this.id == "" ? this.$t('Add User') : this.$t('Edit User')
        },
    },

    created: function()
    {
        if(this.$route.query.id){
            this.id = this.$route.query.id
            this.getItem();
        }
        this.fetchRoles()
    },

    methods: {
        fetchRoles()
        {
            let uri = this.$urlPrefix + '/Role/GetAll';
            this.axios.get(uri).then((response) => {
                this.roles = response.data;
            });
        },
        getItem()
        {
            this.loading = true
            let uri = this.$urlPrefix + '/User/Get?id=' + this.id;
            this.axios.get(uri).then((response) => {
                this.item = response.data;
                this.loading = false
            });
        },
        save () {
            if(this.$refs.form.validate()){
                this.loading = true
                if (this.id != "") {
                    let uri = this.$urlPrefix + '/User/Update/';
                    this.axios.put(uri, this.item).then((response) => {
                        if(response.status == 204 || response.status == 200){
                                this.loading = false
                                this.$router.push({name: 'Users', query: { page: this.$route.query.page }});
                        }
                    });
                } else {
                    let uri = this.$urlPrefix + '/User/Add';
                    this.axios.post(uri, this.item).then((response) => {
                        if(response.status == 200){
                            this.item = {}
                            this.loading = false
                            this.$router.push({name: 'Users', query: { page: this.$route.query.page }});
                        }
                    });
                }
            }
        },
        sendActiveEmail(){
            var scope = this
            scope.sendActivateLoading = true;
            let uri = scope.$urlPrefix + '/Email/ActivateUser';
            scope.axios.post(uri, {"userId":scope.id}).then((response) => {
                scope.sendActivateLoading = false;
                if(response.status == 200){
                    scope.$store.dispatch('setSnack', {icon:"mdi-thumb-up", color:'green', msg:scope.$t("Email Sent!")})
                }else{
                    scope.$store.dispatch('setSnack', {icon:"mdi-alert", color:'red', msg:scope.$t("Unable to send activation email.")})
                }
            })
            .catch(error => {
              scope.sendActivateLoading = false;
              scope.$store.dispatch('setSnack', {icon:"mdi-alert", color:'red', msg:scope.$t(error.response.data)})
            });
        },
    }
}
</script>
